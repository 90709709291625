import { OpenAI } from "langchain/llms/openai";
import { PromptTemplate } from "langchain/prompts";
import { LLMChain } from "langchain/chains";
import { loadQAStuffChain } from "langchain/chains";
import { Document } from "langchain/document";
import { CallbackManager } from "langchain/callbacks";

const OPEN_AI_KEY = "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";
const OPEN_AI_ORG_ID = "org-GvzS4CVMygPRAQYWM2Aoca78";

//https://github.com/go-skynet/LocalAI/blob/master/examples/langchain/langchainjs-localai-example/src/index.mts
// https://github.com/hwchase17/langchainjs/discussions/276

export const runOpenAI = async () => {
  console.log("runOpenAI");
  //Instantiante the OpenAI model
  //Pass the "temperature" parameter which controls the RANDOMNESS of the model's output. A lower temperature will result in more predictable output, while a higher temperature will result in more random output. The temperature parameter is set between 0 and 1, with 0 being the most predictable and 1 being the most random
  const model = new OpenAI(
    {
      streaming: true,
      temperature: 0.9,
      openAIApiKey: OPEN_AI_KEY,
      callbackManager: CallbackManager.fromHandlers({
        handleLLMNewToken(token: string) {
          console.log(token);
        },
      }),
    },
    {
      organization: "org-GvzS4CVMygPRAQYWM2Aoca78",
      apiKey: process.env.OPENAI_API_KEY || OPEN_AI_KEY,
      basePath: "https://oai.hconeai.com/v1",
      baseOptions: {
        headers: {
          "Helicone-Auth": "Bearer sk-my73tca-2gtucgi-wo7xinq-44cg4ka",
        },
      },
    }
  );

  //Create the template. The template is actually a "parameterized prompt". A "parameterized prompt" is a prompt in which the input parameter names are used and the parameter values are supplied from external input
  const template = "What is a good name for a company that makes {product}?";

  //Instantiate "PromptTemplate" passing the prompt template string initialized above and a list of variable names the final prompt template will expect
  const prompt = new PromptTemplate({ template, inputVariables: ["product"] });

  //Instantiate LLMChain, which consists of a PromptTemplate and an LLM. Pass the result from the PromptTemplate and the OpenAI LLM model
  const chain = new LLMChain({ llm: model, prompt });
  console.log("before res");
  //Run the chain. Pass the value for the variable name that was sent in the "inputVariables" list passed to "PromptTemplate" initialization call
  try {
    const res = await chain.call({ product: "colorful socks" });
    console.log("after res");
    console.log({ res });
  } catch (error) {
    console.log({ error });
  }
};

//run();

export const searchDocument = async () => {
  const model = new OpenAI(
    {
      temperature: 0.9,
      openAIApiKey: OPEN_AI_KEY,
    },
    {
      organization: "org-GvzS4CVMygPRAQYWM2Aoca78",
      apiKey: process.env.OPENAI_API_KEY || OPEN_AI_KEY,
      basePath: "https://oai.hconeai.com/v1",
      baseOptions: {
        headers: {
          "Helicone-Auth": "Bearer sk-my73tca-2gtucgi-wo7xinq-44cg4ka",
        },
      },
    }
  );
  const chainA = loadQAStuffChain(model);
  const docs = [
    new Document({ pageContent: "Harrison went to Harvard." }),
    new Document({ pageContent: "Ankush went to Princeton." }),
  ];
  const resA = await chainA.call({
    input_documents: docs,
    question: "Where did Harrison go to college?",
  });
  console.log({ resA });
};

//https://github.com/Yidadaa/ChatGPT-Next-Web/blob/main/scripts/fetch-prompts.mjs
