import * as React from "react";
import { Button, View, Text, Image, StyleSheet } from "react-native";
import ICON_APP from "../../meta/icon.png";
import { s, vs, ms } from "@config/scale";

export default function Header() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        marginLeft: vs(16),
      }}
    >
      <Text
        style={{
          fontSize: vs(16),
          color: "#1c1e21",
          fontWeight: "bold",
        }}
      >
        Family First
      </Text>
      <View style={styles.lang}>
        <Text style={styles.en}>
          {`EN`}
        </Text>
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  
  lang: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: vs(10),
    paddingVertical: 2,
    paddingHorizontal: 6,
    backgroundColor: '#efefef',
    borderRadius: vs(25),
    marginHorizontal: vs(6),
  },
  en: {
    height: vs(11),
    fontSize: vs(9),
    fontWeight: '700',
    color: '#000000',
  },
});
