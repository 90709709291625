import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//https://firebase.google.com/docs/web/modular-upgrade
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDodtOhjLBvngv-Pq-s3AySbat8LN3TeSA",
  authDomain: "familyfirstdotpage.firebaseapp.com",
  databaseURL: "https://familyfirstdotpage-default-rtdb.firebaseio.com",
  projectId: "familyfirstdotpage",
  storageBucket: "familyfirstdotpage.appspot.com",
  messagingSenderId: "941247503032",
  appId: "1:941247503032:web:c39f8714420d22a637324d",
  measurementId: "G-5LWDGKQX34",
};
firebase.initializeApp(firebaseConfig);


export default firebase.firestore;
