import * as React from "react";
import { Button, View, Text, Image, StyleSheet } from "react-native";
import ICON_APP from "../../meta/icon.png";
import { s, vs, ms } from "@config/scale";

export default function Header() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Image style={{ width: vs(35), height: vs(35) }} source={ICON_APP} />
    </View>
  );
}


const styles = StyleSheet.create({
  
  lang: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: vs(10),
    paddingVertical: 2,
    paddingHorizontal: 6,
    backgroundColor: '#efefef',
    borderRadius: vs(25),
    marginHorizontal: vs(6),
  },
  en: {
    height: vs(11),
    fontSize: vs(9),
    fontWeight: '700',
    color: '#000000',
  },
});
