import { Dimensions, Platform, StatusBar } from "react-native";
const SIZE_MATTERS_BASE_WIDTH = 390;
const SIZE_MATTERS_BASE_HEIGHT = 776;

const { width, height } = Dimensions.get("window");
const [shortDimension, longDimension] =
  width < height ? [width, height] : [height, width];

//Default guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = SIZE_MATTERS_BASE_WIDTH || 350;
const guidelineBaseHeight = SIZE_MATTERS_BASE_HEIGHT || 680;

export const scale = (size) => (shortDimension / guidelineBaseWidth) * size;
export const verticalScale = (size) => {

  // fix for 4k resolution
  let ratio = longDimension / guidelineBaseHeight;
  if (ratio > 1.2) {
    ratio = 1.2;
  }
  return ratio * size;
};
export const moderateScale = (size, factor = 0.5) =>
  size + (scale(size) - size) * factor;
export const moderateVerticalScale = (size, factor = 0.5) =>
  size + (verticalScale(size) - size) * factor;

export const s = scale;
export const vs = verticalScale;
export const ms = moderateScale;
export const mvs = moderateVerticalScale;
export const wW = width;
export const wH = height;