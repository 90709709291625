import * as React from "react";
import { Button, View, Text, Image } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import InteractiveChat from "@screens/InteractiveChat";
import Home from "@screens/Home";
import Header from "@components/Header";

import firestore from "@config/firestore";
import ICON_APP from "./meta/icon.png";
import { s, vs, ms } from "@config/scale";
import Gify from "./src/screens/Gify";
import ErrorBoundary from "react-native-error-boundary";
import HeaderLeft from "@components/HeaderLeft";
import HeaderRight from "@components/HeaderRight";

function HomeScreen({ navigation, userId = "JtlxGbt4jEADOX5qkKd0" }) {
  // usestate for name
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    const subscriber = firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot(
        (documentSnapshot) => {
          console.log("User data: ", documentSnapshot?.data());
          setName(documentSnapshot?.data()?.name);
        }, // onNext
        (error) => console.error(error) // onError
      );

    // Stop listening for updates when no longer required
    return () => subscriber();
  }, [userId]);

  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text>Welcome to 🌻Family AI</Text>
      <Text>{name}</Text>
      <View style={{ margin: 20 }}>
        <Button
          title="+ Create Chat"
          onPress={() => navigation.navigate("Chat")}
        />
      </View>
    </View>
  );
}

function ProfileScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Button
        title="Go to Notifications"
        onPress={() => navigation.navigate("Notifications")}
      />
      <Button title="Go back" onPress={() => navigation.goBack()} />
    </View>
  );
}

function NotificationsScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Button
        title="Go to Settings"
        onPress={() => navigation.navigate("Settings")}
      />
      <Button title="Go back" onPress={() => navigation.goBack()} />
    </View>
  );
}

function SettingsScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Button title="Go back" onPress={() => navigation.goBack()} />
    </View>
  );
}

const Stack = createNativeStackNavigator();

function AppStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="FamilyFirst"
        options={{
          headerTitle: (props) => <Header />,
          headerTitleAlign: 'center',
          headerLeft: (props) => <HeaderLeft />,
          headerRight: () => <HeaderRight />,
        }}
        component={Home}
      />
      <Stack.Screen name="Notifications" component={NotificationsScreen} />
      <Stack.Screen
        name="Chat"
        options={{ 
          headerTitleAlign: 'center',
          headerTitle: (props) => <Header /> }}
        component={InteractiveChat}
      />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="Settings" component={SettingsScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

export default function App() {
  return (
    <ErrorBoundary>
      <NavigationContainer>
        <AppStack />
      </NavigationContainer>
    </ErrorBoundary>
  );
}
