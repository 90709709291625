import * as React from "react";
import { Button, View, Text, Platform, Image, StyleSheet } from "react-native";
import firestore from "@config/firestore";
//import ICON_APP from "./meta/icon.png";
import { s, vs, ms } from "@config/scale";
import Footer from "../components/Footer";
import Purchases, { PurchasesOffering } from "react-native-purchases";
import { runOpenAI, searchDocument } from "../services/openai";
import NO_CHATS from "../assets/NoChats.png";

const APIKeys = {
  apple: "appl_vTmfIvfGrmRRDOMfNqhPngMeysx",
  google: "goog_IJaeGalfmNowkMsurctMekzYjrm",
};

function HomeScreen({ navigation, userId = "JtlxGbt4jEADOX5qkKd0" }) {
  // usestate for name
  const [name, setName] = React.useState("");
  const [currentOffering, setCurrentOffering] =
    React.useState<PurchasesOffering | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      if (Platform.OS == "android") {
        await Purchases.configure({ apiKey: APIKeys.google });
      } else {
        await Purchases.configure({ apiKey: APIKeys.apple });
      }

      const offerings = await Purchases.getOfferings();
      setCurrentOffering(offerings.current);
      console.log(offerings, "offerings");
      return offerings;
    };

    Purchases.setDebugLogsEnabled(true);

    fetchData().catch(console.log);
  }, []);

  React.useEffect(() => {
    const subscriber = firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot(
        (documentSnapshot) => {
          console.log("User data: ", documentSnapshot?.data());
          setName(documentSnapshot?.data()?.name);
        }, // onNext
        (error) => console.error(error) // onError
      );

    // Stop listening for updates when no longer required
    return () => subscriber();
  }, [userId]);

  React.useEffect(() => {
    //call chat openai
    runOpenAI();
    searchDocument();
  }, []);
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: "#fff",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          margin: 20,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flex: 1,
        }}
      >
        <Image source={NO_CHATS} style={{ width: vs(289), height: vs(311) }} />
        <View style={styles.root}>
          <Text style={styles.startYourFirstFamilyfriendlyAiAssistantChat}>
            {`Start your first family-friendly AI Assistant Chat`}
          </Text>
          <Text style={styles.shareYourChatWithFamilyAndFriends}>
            {`Share your chat with family and friends`}
          </Text>
        </View>
      </View>
      {false && (
        <View
          style={{
            margin: 20,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flex: 1,
          }}
        >
          <Text>Welcome to 🌻Family First</Text>
          <Text>{name}</Text>
          <Text>
            Package Count: {currentOffering?.availablePackages?.length}
          </Text>
          {currentOffering?.availablePackages?.map((pkg) => {
            return <Text>{pkg.product.identifier}</Text>;
          })}
        </View>
      )}
      <Footer onPress={() => navigation.navigate("Chat")} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: vs(12),
    marginTop: vs(28),
  },
  startYourFirstFamilyfriendlyAiAssistantChat: {
    fontSize: vs(12),
    fontWeight: "600",
    textAlign: "left",
    textAlignVertical: "top",
    color: "#666666",
  },
  shareYourChatWithFamilyAndFriends: {
    fontSize: vs(12),
    fontWeight: "400",
    textAlign: "left",
    color: "#666666",
  },
});

export default HomeScreen;
